import Axios, { CancelTokenSource } from "axios";
import {
  browserName,
  fullBrowserVersion,
  osName,
  isMobile,
} from "react-device-detect";
import { coreGet } from "./core";
import { guestServiceProvider, ServiceProvider } from "./ServiceProvider";
import { convertArrayToObject } from "../utils/helpers";
import { store } from "../redux/store";
import { pushErrorInGA } from "../utils/datalayers";
import { Constants } from "../@types/Constants";
import { TP_REDEMPTION_RATE } from "../@types/Constants";
import { Storage } from "../utils/storage";
import {
  checkEditFlowByPathname,
  checkUserRedemptionEligiblity,
} from "./redemption";
import differenceInDays from "date-fns/differenceInDays";
/**
 *
 * @param {string} crs
 * @param {string} crsCode
 * @param {string} checkin
 * @param {string} checkout
 * @param {number} adults
 * @param {number} children
 * @param {string} promotionCode
 * @param {string} groupCode
 * @param {string|null} rateCode
 * @returns {string}
 */
// ASE - Availibilty Call
export const buildFetchRatesUrl = (
  crs: string,
  crsCode: string,
  checkin: string,
  checkout: string,
  adults: number,
  children: number,
  promotionCode: string | null,
  groupCode: string | any[] | null,
  rateCode: string | null = null,
  chidlrenAges: Array<string | number> = [],
  ratePlanFilterCode?: string | null,
  discount?: string | null,
  EditReservationRate?: string | null,
  siteID?: any
): string => {
  let apiUrl = `${process.env.GATSBY_CORE_BASE_URL}/api/${crs}/availability?_format=json`;
  // let apiUrl = `https://core.redlion.com/api/${crs}/availability?_format=json`;
  const siteId = siteID || process.env.SITE_ID || 31;
  apiUrl += `&siteId=${siteId}`;
  // crsCode = '12667';
  apiUrl += `&Quantity=1`;
  apiUrl += `&Start=${checkin}`;
  apiUrl += `&End=${checkout}`;
  apiUrl += `&Adults=${adults}`;
  apiUrl += `&Children=${children}`;
  apiUrl += `&ChildrenAges=${chidlrenAges.join(",")}`;
  apiUrl += `&HotelCode=${crsCode}`;

  // const ratePlanCode = groupCode ? groupCode : rateCode;
  // Promotion code.
  if (promotionCode !== null && promotionCode.length) {
    // apiUrl += `&PromotionCode=${promotionCode}&ExactMatchOnly=true`;
    apiUrl += `&PromotionCode=${promotionCode.toUpperCase()}`;
  }
  // Discount code AARP senior
  if (discount === "AARP") {
    const discountPromo = "SEN55";
    apiUrl += `&PromotionCode=${discountPromo}`;
  }

  // Group code.
  if (groupCode !== null && groupCode.length) {
    apiUrl += `&GroupCode=${groupCode}`;
  }
  // Detailed rates
  if (rateCode !== null) {
    // Crs-specific params.
    if (crs === "synxis") {
      apiUrl += "&AvailReqType=NonRoom";
    } else {
      apiUrl += "&ResponseType=RateRoomServiceItem";
    }
    // Rate plan code.
    apiUrl += `&RatePlanCode=${rateCode}`;
  }

  // Rate plan filter code
  // if(!rateCode)  {
  const defaultRatePlanFilter = store.getState().ratePlanFilter || "";
  let specialRatePlanFilter = null;
  switch (discount) {
    case "AAA":
      specialRatePlanFilter = "AAA";
      break;
    case "GOV":
    case "MIL":
      specialRatePlanFilter = "GOV";
      break;
    case "PKG":
      specialRatePlanFilter = "PKG";
  }

  const filter =
    specialRatePlanFilter || ratePlanFilterCode || defaultRatePlanFilter;
  if (filter) {
    apiUrl += `&RatePlanFilterCode=${filter}`;
  }
  if (EditReservationRate) {
    if (rateCode !== null) {
      apiUrl += `&RatePlanCode=${rateCode}`;
    } else {
      apiUrl += `&RatePlanCode=${EditReservationRate}`;
    }
    apiUrl += `&PromotionCode=${EditReservationRate}`;
    apiUrl += `&GroupCode=${EditReservationRate}`;
  }
  // }
  return apiUrl;
};

export const getCRSRates = async (
  apiUrl: string,
  source: CancelTokenSource | null
): Promise<any> => {
  const data = await coreGet(apiUrl, source);
  if (
    data &&
    data.error &&
    data.message?.indexOf("Request cancelled for fetch rates") == -1
  ) {
    pushErrorInGA(
      Constants.ERRORSTYPE.AVAILABILITY,
      "",
      `${data.message} -- ${apiUrl}`
    );
  }
  return data;
};

export const getCRSRatesASE = async (
  adults: number,
  children: number,
  chidlrenAges: string,
  crsHotelCode: string,
  chainId: string,
  start: string,
  end: string,
  availReqType: string,
  promotionCode: string,
  ratePlanCode: string,
  ratePlanFilterCode: string,
  abortController: string
): Promise<any> => {
  const guestService = await ServiceProvider.Guest.getGuestClient();
  const hotelAvailablityResponse = await guestService.getHotelAvailability(
    adults,
    children,
    chidlrenAges,
    crsHotelCode,
    chainId,
    start,
    end,
    "NonRoom",
    promotionCode,
    ratePlanCode,
    ratePlanFilterCode,
    abortController
  );
  const hotelData = hotelAvailablityResponse?.hotelAvailablity?.queryHotel;
  // ASE - review
  // if (data && data.error && data.message?.indexOf("Request cancelled for fetch rates") == -1) {
  //   pushErrorInGA(Constants.ERRORSTYPE.AVAILABILITY, '', `${data.message} -- ${apiUrl}`);
  // }
  return hotelData;
};

/**
 *
 * @param {Array<{Object}>} rooms
 * @param {string} discountCode
 * @param {string} promotionCode
 * @param {string} groupCode
 * @returns {null|[]}
 */
export const fetchRoomsAndRates = (
  rooms: { [x: string]: any } | null | undefined,
  discountCode = null,
  promotionCode = null,
  groupCode: string | null
): any[] | null => {
  const filteredRooms: any[] = [];
  if (rooms !== null && rooms !== undefined) {
    Object.keys(rooms).forEach(function (roomCode) {
      const room = rooms[roomCode];
      const rates: any[] = [];
      const selectedRates: any[] = [];
      const discountCodes = ["AAA", "GOV", "PKG"];
      Object.keys(room.Rates).forEach(function (rateCode) {
        const rate = room.Rates[rateCode];

        const defaultTypes = ["public", "member", "package", null];
        const rateObject = {
          ...rate,
          RateCode: rateCode,
        };
        rates.push(rateObject);
        if (promotionCode !== null || groupCode !== null) {
          selectedRates.push(rateObject);
        }
        // Member discounts.
        if (discountCode !== null) {
          // if (rate.RateType === 'plan' && rate.PlanType !== null) {
          // const planType = rate.PlanType;
          // if (discountCode === planType) {
          selectedRates.push(rateObject);
          // }
          // }
        }
        // Parse out base rates.
        else if (defaultTypes.includes(rate.RateType)) {
          selectedRates.push(rateObject);
        }
      });
      if (selectedRates.length) {
        selectedRates.sort(
          (a, b) => parseFloat(a.RoomRate) - parseFloat(b.RoomRate)
        );
        if (rates.length) {
          rates.sort((a, b) => parseFloat(a.RoomRate) - parseFloat(b.RoomRate));
        }
        let baseRate = rates.filter((rateObject) => rateObject.IsBaseRate);
        if (!baseRate[0]) {
          baseRate = rates.filter(
            (rateObject) =>
              rateObject.RateType == "public" || rateObject.RateType != "member"
          );
          baseRate.sort(
            (a, b) => parseFloat(a.RoomRate) - parseFloat(b.RoomRate)
          );
        }
        let fromRate = {
          RoomRate: selectedRates[0].RoomRate,
          RateType: selectedRates[0].RateType,
          RateCode: selectedRates[0].RateCode,
          IsPromotionalRate: selectedRates[0].IsPromotionalRate,
        };
        let discountedRate: {
          RoomRate?: string;
          RateType?: string;
          RateCode?: string;
          IsPromotionalRate?: boolean;
        } = {};

        let discountedRates;
        if (promotionCode || groupCode || discountCode === "AARP") {
          discountedRates = selectedRates.filter(
            (rateObject) => rateObject && rateObject.IsPromotionalRate
          );
        }

        if (discountCode) {
          if (discountCodes.indexOf(discountCode) != -1) {
            discountedRates = selectedRates;
          }
        }
        if (discountedRates && discountedRates.length > 0) {
          discountedRates.sort(
            (a, b) => parseFloat(a.RoomRate) - parseFloat(b.RoomRate)
          );
          discountedRate = discountedRates[0];
        }
        if (Object.keys(discountedRate).length > 0) {
          fromRate = {
            RoomRate: discountedRate.RoomRate,
            RateType: discountedRate.RateType,
            RateCode: discountedRate.RateCode,
            IsPromotionalRate: discountedRate.IsPromotionalRate,
          };
        }

        const currency = rates && rates.length ? rates[0].CurrencyCode : "USD";
        const roomObject = {
          ...room,
          Rates: rates,
          FromRate: fromRate.RoomRate,
          FromRateCode: fromRate.RateCode,
          FromRateType: fromRate.RateType,
          FromRatePromotional: fromRate.IsPromotionalRate,
          BaseRate: baseRate.length ? baseRate[0].RoomRate : null,
          BaseRateCode: baseRate[0] ? baseRate[0].RateCode : null,
          BaseRateType: baseRate[0] ? baseRate[0].RateType : null,
          RoomCode: roomCode,
          Currency: currency,
          LowestRate: rates?.reduce((prev, curr) =>
            prev.Total < curr.Total ? prev : curr
          ).RateCode,
          IsonlyRate: rates?.length === 1,
          RateCount: rates?.length ?? 0,
        };
        filteredRooms.push(roomObject);
      }
    });

    if (filteredRooms.length) {
      // Sort rooms by lowest price.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filteredRooms.sort((f, s) => {
        if (f.FromRatePromotional && s.FromRatePromotional) {
          return parseFloat(f.FromRate) - parseFloat(s.FromRate);
        } else {
          if (f.FromRatePromotional && !s.FromRatePromotional) {
            return -1;
          }
          if (!f.FromRatePromotional && s.FromRatePromotional) {
            return 1;
          }
          if (!f.Sort && !s.Sort) {
            return parseFloat(f.FromRate) - parseFloat(s.FromRate);
          }
        }
      });
      // filteredRooms.sort((a, b) => parseFloat(a.FromRate) - parseFloat(b.FromRate));
      return filteredRooms;
    }
  }
  return null;
};

export const fetchRoomsAndRatesASE = (
  rooms: { [x: string]: any } | null | undefined,
  discountCode = null,
  promotionCode = null,
  groupCode: string | null,
  checkinDate: string | null,
  checkoutDate: string | null,
  redemptionItem
): any[] | null => {
  const filteredRooms: any[] = [];
  const isLoggedIn = Storage.GetLocalStorageValue("isLoggedIn");
  const isRedemptionPromoCode = promotionCode
    ? TP_REDEMPTION_RATE.includes(promotionCode)
    : groupCode
    ? TP_REDEMPTION_RATE.includes(groupCode)
    : false;
  const isEditFlow =
    typeof window !== "undefined" &&
    checkEditFlowByPathname(window.location.pathname);
  const isMultiroom = !isEditFlow && store.getState().search?.rooms?.length > 1;
  let includeRedemptionRate = true;
  if (rooms !== null && rooms !== undefined) {
    rooms.forEach((room: any) => {
      const rates: any[] = [];
      const selectedRates: any[] = [];
      const discountCodes = ["AAA", "GOV", "PKG"];
      if (
        room.roomRates.length === 1 &&
        TP_REDEMPTION_RATE.includes(room.roomRates[0].rateCode) &&
        redemptionItem &&
        checkinDate &&
        checkoutDate &&
        isLoggedIn
      ) {
        const pointsRequired =
          redemptionItem && redemptionItem?.currencyRequired
            ? parseInt(redemptionItem?.currencyRequired)
            : 0;

        const numNights = differenceInDays(
          new Date(checkoutDate),
          new Date(checkinDate)
        );
        const memberPoints = store.getState().member?.memberPointsTally;
        const isEligible = checkUserRedemptionEligiblity(
          memberPoints,
          pointsRequired,
          numNights
        );
        if (!isEligible) {
          includeRedemptionRate = false;
        }
      }
      room.roomRates.forEach((rate: any) => {
        const defaultTypes = ["public", "member", "package", null, ""];
        const rateObject = {
          ...rate,
          RateCode: rate.rateCode,
        };
        const isRedemptionRate = TP_REDEMPTION_RATE.includes(rate.rateCode);

        if (!isRedemptionRate) {
          rates.push(rateObject);
        } else if (
          isRedemptionRate &&
          isLoggedIn &&
          !isMultiroom &&
          !isRedemptionPromoCode &&
          includeRedemptionRate
        ) {
          rates.push(rateObject);
        }
        if (!isRedemptionRate) {
          if (
            promotionCode !== null ||
            groupCode !== null ||
            discountCode !== null
          ) {
            selectedRates.push(rate);
          } else if (defaultTypes.includes(rate.rateType)) {
            selectedRates.push(rate);
          }
        } else if (
          isRedemptionRate &&
          isLoggedIn &&
          !isMultiroom &&
          !isRedemptionPromoCode &&
          includeRedemptionRate
        ) {
          selectedRates.push(rate);
        }
      });
      if (selectedRates.length) {
        selectedRates.sort(
          (a, b) => parseFloat(a.roomRate) - parseFloat(b.roomRate)
        );
        if (rates.length) {
          rates.sort((a, b) => parseFloat(a.roomRate) - parseFloat(b.roomRate));
        }
        let baseRate = rates.filter((rateObject) => rateObject.isBaseRate);
        if (!baseRate[0]) {
          baseRate = rates.filter(
            (rateObject) =>
              rateObject.rateType == "public" || rateObject.rateType != "member"
          );
          baseRate.sort(
            (a, b) => parseFloat(a.roomRate) - parseFloat(b.roomRate)
          );
        }
        let fromRate = {};
        if (
          selectedRates.length > 1 &&
          TP_REDEMPTION_RATE.includes(selectedRates[0].rateCode)
        ) {
          fromRate = {
            roomRate: selectedRates[1].roomRate,
            rateType: selectedRates[1].rateType,
            rateCode: selectedRates[1].rateCode,
            isPromotionalRate: selectedRates[1].isPromotionalRate,
          };
        } else {
          fromRate = {
            roomRate: selectedRates[0].roomRate,
            rateType: selectedRates[0].rateType,
            rateCode: selectedRates[0].rateCode,
            isPromotionalRate: selectedRates[0].isPromotionalRate,
          };
        }
        let discountedRate: {
          roomRate?: string;
          rateType?: string;
          rateCode?: string;
          isPromotionalRate?: boolean;
        } = {};
        let discountedRates;
        if (promotionCode || groupCode || discountCode === "AARP") {
          discountedRates = selectedRates.filter(
            (rateObject) => rateObject && rateObject.isPromotionalRate
          );
        }
        if (discountCode) {
          if (discountCodes.indexOf(discountCode) != -1) {
            discountedRates = selectedRates;
          }
        }
        if (discountedRates && discountedRates.length > 0) {
          discountedRates.sort(
            (a, b) => parseFloat(a.roomRate) - parseFloat(b.roomRate)
          );
          if (
            discountedRates.length > 1 &&
            TP_REDEMPTION_RATE.includes(discountedRates[0].rateCode)
          ) {
            discountedRate = discountedRates[1];
          } else {
            discountedRate = discountedRates[0];
          }
        }
        if (Object.keys(discountedRate).length > 0) {
          fromRate = {
            roomRate: discountedRate.roomRate,
            rateType: discountedRate.rateType,
            rateCode: discountedRate.rateCode,
            isPromotionalRate: discountedRate.isPromotionalRate,
          };
        }
        const currency = rates && rates.length ? rates[0].currencyCode : "USD";
        const roomObject = {
          ...room,
          Rates: rates,
          FromRate: fromRate.roomRate,
          FromRateCode: fromRate.rateCode,
          FromRateType: fromRate.rateType,
          FromRatePromotional: fromRate.isPromotionalRate,
          BaseRate: baseRate.length ? baseRate[0].roomRate : null,
          BaseRateCode: baseRate[0] ? baseRate[0].rateCode : null,
          BaseRateType: baseRate[0] ? baseRate[0].rateType : null,
          RoomCode: room.code,
          Currency: currency,
          LowestRate: rates?.reduce((prev, curr) =>
            prev.total < curr.total ? prev : curr
          ).rateCode,
          IsonlyRate: rates?.length === 1,
          RateCount: rates?.length ?? 0,
        };
        filteredRooms.push(roomObject);
      }
    });
    if (filteredRooms.length) {
      // Sort rooms by lowest price.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filteredRooms.sort((f, s) => {
        if (f.FromRatePromotional && s.FromRatePromotional) {
          return parseFloat(f.FromRate) - parseFloat(s.FromRate);
        } else {
          if (f.FromRatePromotional && !s.FromRatePromotional) {
            return -1;
          }
          if (!f.FromRatePromotional && s.FromRatePromotional) {
            return 1;
          }
          if (!f.Sort && !s.Sort) {
            return parseFloat(f.FromRate) - parseFloat(s.FromRate);
          }
        }
      });
      // filteredRooms.sort((a, b) => parseFloat(a.FromRate) - parseFloat(b.FromRate));
      return filteredRooms;
    }
  }
  return null;
};

export const setServicesDataAttributes = (service: {
  ServicePricingType: { toString: () => string };
  Title: string;
  Price: { Rate: string; Taxes: string };
}): {
  "data-price-per-stay": boolean;
  "data-price-per-night": boolean;
  "data-price-per-item": boolean;
  "data-rate": number;
} => {
  const priceType = service.ServicePricingType.toString().toLowerCase().trim();
  const name = service.Title.toLowerCase().trim();
  const rate = parseFloat(service.Price.Rate);
  let PricePerItem = false;
  let PricePerNight = false;
  let PricePerStay = false;
  switch (priceType) {
    case "per stay":
    case "per room":
      {
        PricePerStay = true;
      }
      break;
    case "per night":
    case "per adult per night":
    case "per room per night":
      {
        PricePerNight = true;
      }
      break;
    case "per item":
    case "per person":
    case "per person per night": {
      PricePerItem = true;
    }
  }
  // Override breakfast pricing (ADO 6507).
  if (name === "breakfast" && rate > 0) {
    PricePerNight = true;
    PricePerItem = true;
  }
  const attributes = {
    "data-price-per-stay": PricePerStay,
    "data-price-per-night": PricePerNight,
    "data-price-per-item": PricePerItem,
    "data-rate": rate,
  };
  return attributes;
};

export const getOTARates = async (
  params: {
    wtoken: any;
    checkin: any;
    checkout: any;
    adults: any;
    children: any;
    bestPrice: any;
    totalRooms: any;
  },
  source: any | null
): Promise<any> => {
  const url = "https://ratematch-us-prd.reztrip.io/widget/otarates";
  const config: any = {
    headers: {
      "X-api-key": process.env.GATSBY_RATEMATCH_API_KEY,
    },
  };
  if (source) {
    config.cancelToken = source.token;
  }

  const requestData = {
    wtoken: params.wtoken,
    arrival_date: params.checkin, //"2020-10-30",
    departure_date: params.checkout, //"2020-11-01",
    num_rates_display: 5, //how many maximum ota rates we want pull and show
    num_adults: params.adults,
    num_children: params.children,
    child_age: [],
    hotel_lowest_price: params.bestPrice,
    num_rooms: params.totalRooms,
    currency: "USD",
    widget_request_client_time_zone:
      Intl.DateTimeFormat().resolvedOptions().timeZone, //"Asia/Calcutta", //guest time zone - from user agent
    widget_request_client_time: new Date(),
    device_type: isMobile ? "mobile" : "desktop",
    device_OS: osName, //"macOS",
    browser_name: browserName, //"Chrome",
    browser_version: fullBrowserVersion,
    language: "en",
  };
  try {
    const response = await Axios.post(url, requestData, config);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    if (
      error.message &&
      error.message.indexOf("Request failed with status code 504") != -1
    ) {
      if (typeof window !== "undefined") {
        (window as any).dataLayer = (window as any).dataLayer || [];
        (window as any).dataLayer.push({ event: "RMWidgetNotSeen-APITimeout" });
        (window as any).dataLayer.push({ event: "RMErrorAPITimeout" });
      }
    }
    return null;
  }
};

export const containsGroupCode = (rooms: any[], groupCode: string): boolean => {
  let groupCodeFound = false;
  rooms &&
    rooms.forEach((room: { Rates: any }) => {
      let rates = Object.keys(room.Rates);
      if (rates[0] !== null) {
        const ratesObject = convertArrayToObject(room.Rates, "RateCode");
        rates = Object.keys(ratesObject);
      }
      if (rates.length > 0 && rates.indexOf("G:" + groupCode) != -1) {
        groupCodeFound = true;
      }
    });
  return groupCodeFound;
};
